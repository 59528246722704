import {Inject, Injectable} from '@angular/core';
import {PagedAsyncIterableIterator} from '@azure/core-paging';
const {BlobServiceClient} = require('@azure/storage-blob');
import {Observable} from 'rxjs/internal/Observable';
import {scan, startWith} from 'rxjs/operators';
import {BlobRestService} from './api/blob.rest-service';

@Injectable({
    providedIn: 'root'
})

export class BlobService {
    containerName = 'marketing-management';

    constructor(private blobRestService: BlobRestService,
                @Inject('STORAGE') private STORAGE: string) {
    }

    async getData(blobname: string) {
        const response = await this.blobRestService.getSasToken().toPromise();
        const blobServiceClient = new BlobServiceClient(`${this.STORAGE}${response.returnObject}`);
        const client = blobServiceClient.getContainerClient(this.containerName);
        const blobClient = client.getBlobClient(blobname);
        const downloadBlockBlobResponse = await blobClient.download();
        return await this.blobToString(await downloadBlockBlobResponse.blobBody);
    }

    async setData(blobname, content) {
        const response = await this.blobRestService.getSasToken().toPromise();
        const blobServiceClient = new BlobServiceClient(`${this.STORAGE}${response.returnObject}`);
        const client = blobServiceClient.getContainerClient(this.containerName);
        const blobClient = client.getBlockBlobClient(blobname);
        const uploadBlobResponse = await blobClient.upload(content, content.length);
        return {errorCode: uploadBlobResponse.errorCode, requestId: uploadBlobResponse.requestId};
    }

    async uploadImage(file, blobname) {
        const response = await this.blobRestService.getSasToken().toPromise();
        const blobServiceClient = new BlobServiceClient(`${this.STORAGE}${response.returnObject}`);
        const client = blobServiceClient.getContainerClient(this.containerName);
        const blobClient = client.getBlockBlobClient(blobname);
        const uploadImageResponse = await blobClient.uploadData(file, {blobHTTPHeaders: {blobContentType: file.type}});
        return {errorCode: uploadImageResponse.errorCode, requestId: uploadImageResponse.requestId};
    }

    async deleteImage(blobname) {
        const response = await this.blobRestService.getSasToken().toPromise();
        const blobServiceClient = new BlobServiceClient(`${this.STORAGE}${response.returnObject}`);
        const client = blobServiceClient.getContainerClient(this.containerName);
        const blobClient = client.getBlockBlobClient(blobname);
        const deleteImageResponse = await blobClient.deleteIfExists({deleteSnapshots: 'include'});
        return {errorCode: deleteImageResponse.errorCode, requestId: deleteImageResponse.requestId};
    }

    async blobToString(blob) {
        const fileReader = new FileReader();
        return new Promise((resolve, reject) => {
            fileReader.onloadend = (ev) => {
                resolve(ev.target.result);
            };
            fileReader.onerror = reject;
            fileReader.readAsText(blob);
        });
    }

    private asyncToObservable<T, TService>(
        iterable: PagedAsyncIterableIterator<T, TService>
    ) {
        return new Observable<T>(
            observer =>
                void (async () => {
                    try {
                        for await (const item of iterable as AsyncIterable<T>) {
                            if (observer.closed) {
                                return;
                            }
                            observer.next(item);
                        }
                        observer.complete();
                    } catch (e) {
                        observer.error(e);
                    }
                })()
        ).pipe(
            scan<T, T[]>((items, item) => [...items, item], []),
            startWith([] as T[])
        );
    }
}
